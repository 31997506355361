import "./Plastiic.css";
import "../Project.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Plastiic() {
  const images = [
    {
      original: require("./plastiic-home.png"),
      description: "Home Page",
    },
    {
      original: require("./plastiic-estimate.png"),
      description: "Estimator Page",
    },
    {
      original: require("./plastiic-contact.png"),
      description: "Contact Page",
    },
  ];

  return (
    <div className="page-grid">
      <div className="page-center project-center">
        <div className="project-title">
          <h1>
            Plastiic.ca{" "}
            <a
              href="https://www.plastiic.ca/home"
              target="_blank"
              rel="noreferrer"
            >
              <FaExternalLinkAlt />
            </a>
          </h1>
        </div>
        <div className="project-body">
          <div className="project-desc">
            <p>
              Plastiic.ca is a website that I created for my small 3D printing
              business. The website helps people gather quick cost estimates for
              their 3D printing projects and provides them with a quick and easy
              way to contact me. The website also features a model visualizer
              that allows users to upload their own 3D models and see them in a
              3D environment.
            </p>
          </div>
          <div className="project-stack">
            <h2>Technologies Used:</h2>
            <ul>
              <li>JavaScript</li>
              <li>React</li>
              <li>Three.js</li>
              <li>Node.js</li>
              <li>Express</li>
              <li>Supabase (PostgreSQL)</li>
              <li>Azure Linux VM</li>
              <li>Vercel</li>
            </ul>
          </div>
          <div className="project-features">
            <h2>Features Built:</h2>
            <ul>
              <li>
                <span>Model Visualizer:</span> Used Three.js to render
                interactive 3D models uploaded by the user along with a frame
                representing the print space available
              </li>
              <li>
                <span>Cost Estimator:</span> Built a file slicing API on an
                Azure Linux VM that accepts an STL file and returns the length
                of filament required to print the model. I integrated this API
                with an Express server to calculate and return the estimated
                cost of the print to the client
              </li>
              <li>
                <span>Contact Form:</span> Integrated Supabase into the server
                to store the information provided in the contact form in a
                PostgreSQL database
              </li>
            </ul>
          </div>
        </div>
        <div className="project-gallery">
          <ImageGallery
            autoPlay
            showPlayButton={false}
            slideDuration={1000}
            slideInterval={6000}
            items={images}
          />
        </div>
      </div>
    </div>
  );
}

export default Plastiic;
