import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Utilities/ScrollToTop";
import Landing from "./Pages/Landing/Landing";
import Nav from "./Components/Nav";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Home from "./Pages/Home/Home";
import Projects from "./Pages/Projects/Projects";
import Resume from "./Pages/Resume/Resume";
import NoPage from "./Pages/NoPage/NoPage";
import Plastiic from "./Pages/Projects/Plastiic/Plastiic";
import Trillium from "./Pages/Projects/Trillium/Trillium";
import MovieMate from "./Pages/Projects/MovieMate/MovieMate";
import Vault24 from "./Pages/Projects/Vault24/Vault24";
import IPayroll from "./Pages/Projects/IPayroll/IPayroll";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {
      <>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/"
              element={
                <>
                  <Nav />
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="home" element={<Home />} />
              <Route path="projects" element={<Projects />} />
              <Route path="projects/plastiic" element={<Plastiic />} />
              <Route path="projects/trillium" element={<Trillium />} />
              <Route path="projects/moviemate" element={<MovieMate />} />
              <Route path="projects/vault24" element={<Vault24 />} />
              <Route path="projects/ipayroll" element={<IPayroll />} />
              <Route path="resume" element={<Resume />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    }
  </React.StrictMode>
);
