import "./Vault24.css";
import "../Project.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Vault24() {
  const images = [
    {
      original: require("./vault24-select.png"),
      description: "Weapon Select Screen",
    },
    {
      original: require("./vault24-upgrades.png"),
      description: "Weapon & Armor Upgrades Menu",
    },
    {
      original: require("./vault24-gameplay.png"),
      description: "Gameplay",
    },
  ];

  return (
    <div className="page-grid">
      <div className="page-center project-center">
        <div className="project-title">
          <h1>
            Vault 24{" "}
            <a
              href="https://github.com/JakeNizio/Vault24"
              target="_blank"
              rel="noreferrer"
            >
              <FaExternalLinkAlt />
            </a>
          </h1>
        </div>
        <div className="project-body">
          <div className="project-desc">
            <p>
              Vault 24 is a game that I developed in Unity using C# with a team
              of 3 other people for my Software Construction (SE2250) course.
              The game is a top-down shooter where the player must collect
              resources, upgrade their weapons and armor, and defeat enemies to
              progress through the levels. The game features a variety of
              weapons and armor that the player can choose from to customize
              their playstyle. The game was developed over the course of 3
              months and was a great learning experience for me as it was my
              first time working on a game project.
            </p>
          </div>
          <div className="project-stack">
            <h2>Technologies Used:</h2>
            <ul>
              <li>C#</li>
              <li>Unity</li>
            </ul>
          </div>
          <div className="project-features">
            <h2>Features Built:</h2>
            <ul>
              <li>
                <span>Weapons Class:</span> Designed three weapons with unique
                attack styles and stats using a hierarchical class-based system
                to enhance code reusability and maintainability
              </li>
              <li>
                <span>Armour Class:</span> Designed an armor system with
                distinct stats to enhance the players defensive abilities
              </li>
              <li>
                <span>Upgrades Shop:</span> Implemented an upgrade shop where
                players can purchase enhancements for their weapons and armor,
                which are then consumed by the corresponding equipment to boost
                stats
              </li>
            </ul>
          </div>
        </div>
        <div className="project-gallery">
          <ImageGallery
            autoPlay
            showPlayButton={false}
            slideDuration={1000}
            slideInterval={6000}
            items={images}
          />
        </div>
      </div>
    </div>
  );
}

export default Vault24;
