import "./MovieMate.css";
import "../Project.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function MovieMate() {
  const images = [
    {
      original: require("./moviemate-2.png"),
      description: "Top View Model",
    },
    {
      original: require("./moviemate-1.png"),
      description: "Internal View Model",
    },
    {
      original: require("./moviemate-3.png"),
      description: "Back View Model",
    },
    {
      original: require("./moviemate-4.jpg"),
      description: "Top View Product",
    },
    {
      original: require("./moviemate-5.jpg"),
      description: "Top View Product",
    },
    {
      original: require("./moviemate-6.jpg"),
      description: "Back View Product",
    },
    {
      original: require("./moviemate-7.jpg"),
      description: "Team Picture",
    },
    {
      original: require("./moviemate-8.jpg"),
      description: "Team Picture",
    },
  ];

  return (
    <div className="page-grid">
      <div className="page-center project-center">
        <div className="project-title">
          <h1>
            MovieMate{" "}
            <a
              href="https://cad.onshape.com/documents/238612bfcb9b7048719f1961/w/ccb07fa7e861208dee64cd2a/e/2be9a3c7e016bb25f00b4c0c?renderMode=0&uiState=66db80a212896b37f2346d25"
              target="_blank"
              rel="noreferrer"
            >
              <FaExternalLinkAlt />
            </a>
          </h1>
        </div>
        <div className="project-body">
          <div className="project-desc">
            <p>
              MovieMate is an accessible remote control capable of cloning and
              sending any infrared signal. Developed for a client with cerebral
              palsy and severe cognitive impairments, I led a team of five in
              creating a solution that was programmable in under five seconds,
              exceeded all accessibility requirements, and boasted a battery
              life of over two years. The device earned us 2nd place out of 140+
              teams and 1st place in our category at the 2022 Western
              Engineering Competition
            </p>
          </div>
          <div className="project-stack">
            <h2>Technologies Used:</h2>
            <ul>
              <li>Arduino</li>
              <li>C++</li>
              <li>Onshape</li>
              <li>FDM 3D Printer</li>
            </ul>
          </div>
          <div className="project-features">
            <h2>Features Built:</h2>
            <ul>
              <li>
                <span>Final Prototype:</span> Designed the case and buttons in
                Onshape, 3D-printed the components using PLA, wired the internal
                components, and assembled the final product
              </li>
              <li>
                <span>Cloning Protocol:</span> Developed the cloning protocol in
                C++ to capture and clone signals from any infrared remote into
                EEPROM storage, utilizing an Arduino and IR receiver
              </li>
              <li>
                <span>Send Protocol:</span> Developed a C++ protocol that
                responds to button presses by retrieving and transmitting the
                cloned signal via an Arduino and an array of IR LEDs
              </li>
            </ul>
          </div>
        </div>
        <div className="project-gallery">
          <ImageGallery
            autoPlay
            showPlayButton={false}
            slideDuration={1000}
            slideInterval={6000}
            items={images}
          />
        </div>
      </div>
    </div>
  );
}

export default MovieMate;
