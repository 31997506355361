import "./Projects.css";
import { Link } from "react-router-dom";

function Projects() {
  return (
    <div className="page-grid">
      <div className="page-center" id="projects-center">
        <div id="resume-title">
          <h1>My Projects</h1>
        </div>
        <div id="projects-gallery">
          <Link
            to={"/projects/plastiic"}
            className="projects-project"
            id="projects-plastiic"
          >
            <div className="projects-projectmask"></div>
            <h2>Plastiic.ca</h2>
            <div className="projects-reveal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tincidunt, nunc in euismod ullamcorper, nisl felis ultrices
                eros, vel tincidunt justo mi et odio.
              </p>
            </div>
          </Link>
          <Link
            to={"/projects/trillium"}
            className="projects-project"
            id="projects-trillium"
          >
            <div className="projects-projectmask"></div>
            <h2>Trillium</h2>
            <div className="projects-reveal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tincidunt, nunc in euismod ullamcorper, nisl felis ultrices
                eros, vel tincidunt justo mi et odio.
              </p>
            </div>
          </Link>
          <Link
            to={"/projects/ipayroll"}
            className="projects-project"
            id="projects-ipayroll"
          >
            <div className="projects-projectmask"></div>
            <h2>iPayroll</h2>
            <div className="projects-reveal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tincidunt, nunc in euismod ullamcorper, nisl felis ultrices
                eros, vel tincidunt justo mi et odio.
              </p>
            </div>
          </Link>
          <Link
            to={"/projects/vault24"}
            className="projects-project"
            id="projects-vault24"
          >
            <div className="projects-projectmask"></div>
            <h2>Vault 24</h2>
            <div className="projects-reveal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tincidunt, nunc in euismod ullamcorper, nisl felis ultrices
                eros, vel tincidunt justo mi et odio.
              </p>
            </div>
          </Link>
          <Link
            to={"/projects/moviemate"}
            className="projects-project"
            id="projects-moviemate"
          >
            <div className="projects-projectmask"></div>
            <h2>MovieMate</h2>
            <div className="projects-reveal">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                tincidunt, nunc in euismod ullamcorper, nisl felis ultrices
                eros, vel tincidunt justo mi et odio.
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Projects;
