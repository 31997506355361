import "./Trillium.css";
import "../Project.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function Trillium() {
  const images = [
    {
      original: require("./trillium-signup.png"),
      description: "Signup Page",
    },
    {
      original: require("./trillium-login.png"),
      description: "Login Page",
    },
    {
      original: require("./trillium-profile.png"),
      description: "Profile Page",
    },
    {
      original: require("./trillium-swipe.png"),
      description: "Swipe Page",
    },
    {
      original: require("./trillium-detailed.png"),
      description: "Swipe Details Page",
    },
    {
      original: require("./trillium-tonight.png"),
      description: "Nearby Activty Page",
    },
    {
      original: require("./trillium-matches.png"),
      description: "Matches Page",
    },
    {
      original: require("./trillium-chat.png"),
      description: "Chat Page",
    },
    {
      original: require("./trillium-meeting.png"),
      description: "Meeting Location Page",
    },
  ];

  images.map((image) => (image.originalHeight = 600));

  return (
    <div className="page-grid">
      <div className="page-center project-center">
        <div className="project-title">
          <h1>
            Trillium{" "}
            <a
              href="https://github.com/JakeNizio/trillium"
              target="_blank"
              rel="noreferrer"
            >
              <FaExternalLinkAlt />
            </a>
          </h1>
        </div>
        <div className="project-body">
          <div className="project-desc">
            <p>
              Trillium is a mobile application that I created to provide people
              with an alternative to traditional dating apps. The goal of the
              app is to encourage real-life social interactions rather than
              replacing them as is done by traditional apps. Users simply take
              their phones with them to social events and leave the matchmaking
              up to Trillium. After the event, users can swipe through a list of
              potential matches and chat with them to set up a date.
            </p>
          </div>
          <div className="project-stack">
            <h2>Technologies Used:</h2>
            <ul>
              <li>JavaScript</li>
              <li>React Native</li>
              <li>Node.js</li>
              <li>Supabase (PostgreSQL)</li>
              <li>Google Geocoding API</li>
              <li>Google Places API</li>
            </ul>
          </div>
          <div className="project-features">
            <h2>Features Built:</h2>
            <ul>
              <li>
                <span>User Authentication:</span> Integrated Supabase Auth with
                a login form and local storage to deliver a seamless user
                authentication experience
              </li>
              <li>
                <span>Location-Based Matchmaking:</span> Developed a background
                algorithm that collects location data, identifies visited
                establishments, and generates potential matches based on
                proximity and the user's predefined preferences
              </li>
              <li>
                <span>Real-time Messaging:</span> Integrated Supabase Realtime
                database into the app to enable instant messaging between users
                and their matches
              </li>
              <li>
                <span>Nearby Activity Map:</span> Utilized the location data
                collected across all users, along with Google Maps to highlight
                establishments nearby with high user activity
              </li>
            </ul>
          </div>
        </div>
        <div className="project-gallery trillium-gallery">
          <ImageGallery
            autoPlay
            showPlayButton={false}
            slideDuration={1000}
            slideInterval={6000}
            items={images}
          />
        </div>
      </div>
    </div>
  );
}

export default Trillium;
