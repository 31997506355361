import "./Home.css";
import "../Page.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="page-grid">
      <div className="page-center" id="home-center">
        <div id="home-hero">
          <div>
            <div>
              <h1>My most recent project</h1>
              <h2>I developed a website for my 3D printing business</h2>
            </div>
            <Link id="home-hero-button" to={"/projects/plastiic"}>
              <h1>Check it out</h1>
            </Link>
          </div>
        </div>
        <div id="home-testimonies">
          <div className="home-testimony">
            <h1>3rd Year Software Engineering</h1>
            <p>Western University</p>
            <ul>
              <li>4.0 GPA</li>
              <li>Western Developers Society</li>
              <li>Western Quantum Club</li>
            </ul>
          </div>
          <div className="home-testimony">
            <h1>Bachelor of Cognitive Science</h1>
            <p>Carleton University</p>
            <ul>
              <li>3.7 GPA</li>
              <li>David A. Golden Scholarship</li>
              <li>Graduated with Distinction</li>
            </ul>
          </div>
          <div className="home-testimony">
            <h1>Qiskit Global Summer School</h1>
            <p>IBM</p>
            <h1>Google Data Analytics Certificate</h1>
            <p>Coursera</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
