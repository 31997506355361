import "./Nav.css";
import { Link } from "react-router-dom";
import { FaLinkedin, FaGithub } from "react-icons/fa";

function Nav() {
  return (
    <nav id="nav-grid">
      <div id="nav-center">
        <Link id="nav-title" to="/home">
          Jake Nizio
        </Link>
        <div>
          <Link className="nav-link" to="/projects">
            Projects
          </Link>
          <Link className="nav-link" to="/resume">
            Resume
          </Link>
          <p id="nav-divider">|</p>
          <a
            className="nav-link nav-icon"
            href="https://github.com/JakeNizio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
          <a
            className="nav-link nav-icon"
            href="https://www.linkedin.com/in/jakenizio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
