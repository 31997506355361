import "./IPayroll.css";
import "../Project.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

function IPayroll() {
  const images = [
    {
      original: require("./ipayroll-login.png"),
      description: "Login Modal",
    },
    {
      original: require("./ipayroll-menu.png"),
      description: "Admin Menu",
    },
    {
      original: require("./ipayroll-new1.png"),
      description: "New Employee - Personal Information",
    },
    {
      original: require("./ipayroll-new2.png"),
      description: "New Employee - Earnings Data",
    },
    {
      original: require("./ipayroll-new3.png"),
      description: "New Employee - Deductions Data",
    },
    {
      original: require("./ipayroll-create.png"),
      description: "Create Employee",
    },
    {
      original: require("./ipayroll-modify1.png"),
      description: "Modify Employee - Personal Information",
    },
    {
      original: require("./ipayroll-modify2.png"),
      description: "Modify Employee - Earnings Data",
    },
    {
      original: require("./ipayroll-modify3.png"),
      description: "Modify Employee - Deductions Data",
    },
  ];

  return (
    <div className="page-grid">
      <div className="page-center project-center">
        <div className="project-title">
          <h1>
            iPayroll{" "}
            <a
              href="https://github.com/JakeNizio/ipayroll/tree/main"
              target="_blank"
              rel="noreferrer"
            >
              <FaExternalLinkAlt />
            </a>
          </h1>
        </div>
        <div className="project-body">
          <div className="project-desc">
            <p>
              iPayroll is a desktop application I designed and developed under
              the guidance of Dr. Abdelkader Ouda for my Software Design
              (SE2203) course. I was responsible for requirements elicitation,
              analysis, system design, object design, and program
              implementation. In addition to building the application, I
              produced project documentation and UML diagrams. The application
              aims to automate wage management for small businesses. For this
              project, only the adminstrator functions were built out, including
              user creation and employee profile management.
            </p>
          </div>
          <div className="project-stack">
            <h2>Technologies Used:</h2>
            <ul>
              <li>Java</li>
              <li>JavaFX</li>
              <li>JDBC API</li>
              <li>SQL</li>
            </ul>
          </div>
          <div className="project-features">
            <h2>Features Built:</h2>
            <ul>
              <li>
                <span>User Authentication:</span> Implemented authorization
                using SQL and JDBC, incorporating password encryption and
                role-based access control for admin and employee users
              </li>
              <li>
                <span>Administrator Control:</span> Developed user account
                creation features, enabling the creation of new user profiles,
                new earnings and deductions data, and allowing modifications to
                existing employee profiles
              </li>
            </ul>
          </div>
        </div>
        <div className="project-gallery">
          <ImageGallery
            autoPlay
            showPlayButton={false}
            slideDuration={1000}
            slideInterval={6000}
            items={images}
          />
        </div>
      </div>
    </div>
  );
}

export default IPayroll;
