import "./Resume.css";
import "../Page.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";

function Resume() {
  const resumeWidth = () => {
    if (window.innerWidth < 1000) {
      return window.innerWidth - 50;
    } else {
      return 950;
    }
  };

  const [width, setWidth] = useState(resumeWidth());

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.mjs";

    window.addEventListener("resize", () => {
      setWidth(resumeWidth());
    });
  }, []);

  return (
    <div className="page-grid">
      <div className="page-center" id="resume-center">
        <div id="resume-title">
          <h1>My Resume</h1>
          <p>
            You can download my resume by clicking{" "}
            <a href="JakeNizio-Resume.pdf" download>
              here
            </a>
          </p>
        </div>
        <div id="resume-viewer">
          <Document file="JakeNizio-Resume.pdf">
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={width}
            />
          </Document>
        </div>
      </div>
    </div>
  );
}

export default Resume;
